import React, { useState } from 'react';
import { GETREPORTS } from './AdminServices';
import { FcDownload } from "react-icons/fc";

const Reports = () => {
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [reportType, setReportType] = useState('Application Report');

  const handleDownload = async () => {
    if (!fromDate || !toDate) {
      alert('Please select both "From Date" and "To Date".');
      return;
    }
  
    const from = new Date(fromDate);
    const to = new Date(toDate);
    const today = new Date();
  
    if (from > to) {
      alert('"From Date" cannot be after "To Date". Please select a valid date range.');
      return;
    }
  
    if (from > today || to > today) {
      alert('Future dates are not allowed. Please select valid dates.');
      return;
    }
  
    try {
      const response = await GETREPORTS({
        report_type: reportType,
        from_date: fromDate,
        to_date: toDate,
      });
  
      if (response.data.status === 'failure') {
        alert(response.data.message);
      } else {
        if (response.data.message.length === 0) {
          alert('No Data available for the selected date range.');
          return;
        }
  
        const headers = [
          'Full Name',
          'Applicant No',
          'Application Status',
          'Registered User ID',
          'Affiliation Type',
          'City',
        ];
  
        const csvRows = response.data.message.map((record) => [
          record.full_name || 'N/A',
          record.applicant_no || 'N/A',
          record.appl_status || 'N/A',
          record.reg_user_id || 'N/A',
          record.affiliation_type || 'N/A',
          record.city || 'N/A',
        ]);
  
        const csvContent = [headers.join(','), ...csvRows.map((row) => row.join(','))].join('\n');
  
        downloadFile({
          data: csvContent,
          fileName: `${reportType.replace(/\s+/g, '_')}_Report.csv`,
          fileType: 'text/csv',
        });
  
        alert('Report downloaded successfully!');
      }
    } catch (error) {
      console.error('Failed to fetch reports:', error);
      alert('Failed to download the report. Please try again.');
    }
  };

  const downloadFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  return (
    <div className='BxAllSide'>
      <div className='row'>
        <div className='col-xs-12 col-lg-12'>
          <div className='BxApple p-6'>
            <div className='AdinTitle mb-4'>
              <h1>Reports</h1>
            </div>
            
            <div class="row align-items-center">

              <div class="col-md-3 mb-3">
                <label for="fromDate" class="form-label">From Date:</label>
                
                <input
                    type="date"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                    className='form-control'
                  />
              </div>

              <div class="col-md-3 mb-3">
                <label for="toDate" class="form-label">To Date:</label>
                <input
                    type="date"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                    className='form-control'
                  />
              </div>

              <div class="col-md-3 mb-3">
                <label for="reportType" class="form-label">Report Type:</label>
                <select
                    value={reportType}
                    onChange={(e) => setReportType(e.target.value)}
                    style={{ marginLeft: '10px' }}
                    className='form-select'
                  >
                    <option value="Application Report">Application Report</option>
                    <option value="Fee Report">Fee Report</option>
                </select>
                
              </div>

              <div class="col-md-3 mt-4 d-flex align-items-end">
              <FcDownload style={{ width: "26px", marginLeft: "20px", cursor: "pointer" }}  onClick={handleDownload}  />
                  
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;