import { axios as Instance } from "./service";

class HttpRequest {
    delete(url) {
        return Instance.delete(`${url}`);
    }
    get(url) {
        return Instance.get(`${url}`);
    }
    post(url, body) {
        return Instance.post(`${url}`, body);
    }
    put(url, body) {
        return Instance.put(`${url}`, body);
    }
}
const HttpClient = new HttpRequest();
export { HttpClient }