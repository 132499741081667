import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import './ApplicationList.css';
import { APPLICATIONLIST } from './UniversityServices';
import editicon from '../../img/edit-icon.png';
import filter from '../../img/filter.png';
import printicon from '../../img/printer.png';
import PreviewApplication from '../InstituteComponents/PreviewApplication';

const ApplicationList = () => {
  const [filteredApplications, setFilteredApplications] = useState([]); 
  const [statusFilter, setStatusFilter] = useState("All"); 
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" }); 
  const today = new Date().toISOString().split("T")[0];
  const [currentPage, setCurrentPage] = useState(1); 
  const [selectedApplicationId, setSelectedApplicationId] = useState(null);
  const [SelectedAffiliationCode, setSelectedAffiliationCode] = useState(null);
  
  const rowsPerPage = 10; 
  const navigate = useNavigate();
  const printRef = useRef();

  const fetchFilteredApplications = async () => {
    if (dateRange.startDate && !dateRange.endDate) {
      alert("Please select an end date.");
      return;
    }
    if (dateRange.endDate && !dateRange.startDate) {
      alert("Please select a start date.");
      return;
    }

    let payload = {
      status: statusFilter, 
      from_date: dateRange.startDate ,
      to_date: dateRange.endDate
    };

    try {
      const response = await APPLICATIONLIST(payload); 
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        console.log(response.data);
        setFilteredApplications(response.data.message); 
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
  };
  
  useEffect(() => {
    fetchFilteredApplications();
  }, []);

  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = filteredApplications.slice(indexOfFirstRow, indexOfLastRow);

  const totalPages = Math.ceil(filteredApplications.length / rowsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleEdit = (applicationId, formId, application_status, last_page, affiliation_code) => {
    navigate(`/profile/${formId}`, { state:  {
      applicationId: applicationId,
      application_status: application_status,
      last_page: last_page,
      affiliation_code: affiliation_code
    } });
  };

  const handlePrint = (applicationId, affiliation_code) => {
	setSelectedApplicationId(applicationId);
  setSelectedAffiliationCode(affiliation_code);
    setTimeout(() => {
		if (printRef.current) {
		  const printContent = printRef.current.innerHTML;
		  const originalContent = document.body.innerHTML;
  
		  document.body.innerHTML = printContent;
		  window.print();
		  document.body.innerHTML = originalContent;
		  window.location.reload(); // Reload to reapply original styles and content
		}
	  }, 500);
  };

  return (
    <div className="BxAllSide px-0 py-0">
    <div className="BxApple p-6 AdinTitle">
      <h1>Applications List</h1>

      {/* Filters Section */}
      <div className="filters row">
        <div className="col-12 col-lg-4">
        {/* Status Filter */}
        <select className="form-select all-dropselect"
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="Registered">Registered</option>
		  <option value="Applicant Details Submitted">Applicant Details Submitted</option>
          <option value="Documents Uploaded">Documents Uploaded</option>
          <option value="Verified">Verified</option>
          <option value="Expired">Expired</option>
		  <option value="Renewal">Renewal</option>
		  <option value="All">All</option>
        </select>
        </div>
        <div className="col-12 col-lg-2">
        {/* Date Range Filter */}
        <input className="form-control fromFilesclce"
          type="date"
          value={dateRange.startDate}
		  max={today}
          onChange={(e) =>
            setDateRange({ ...dateRange, startDate: e.target.value })
          }
        />
        </div>
        <div className="col-12 col-lg-2">
        <input className="form-control fromFilesclce"
          type="date"
          value={dateRange.endDate}
		  max={today}
          onChange={(e) =>
            setDateRange({ ...dateRange, endDate: e.target.value })
          }
        />
</div>
<div className="col-12 col-lg-1">
<button className="btn fltrbtn" onClick={fetchFilteredApplications}><img src={filter} alt="Filter Icon"/> </button>
  </div>
        
      </div>
      <div className="table-responsive">
      <table className="application-table">
        <thead>
          <tr>
            <th>S.No</th>
            <th>Name</th>
            <th>Mobile No</th>
			<th>Email Id</th>
			<th>Affiliation Program</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {currentRows.map((app, index) => (
            <tr key={app.id}>
              <td>{indexOfFirstRow + index + 1}</td>
              <td>{app.Applicant_Name || 'NA'}</td>
			  <td>{app.Applicant_Mobile || 'NA'}</td>
			  <td>{app.Applicant_Email || 'NA'}</td>
			  <td>{app.affiliation_type || 'NA'}</td>
			  <td>{app.appl_status || 'NA'}</td>
			  <td>
			  <img src={editicon} alt="Edit Icon" style={{cursor: "pointer"}} onClick={() => handleEdit(app.id,app.form_id,app.appl_status,app.last_page,app.affiliation_type)} />
			  <img src={printicon} alt="Print Icon" style={{ width: "26px", marginLeft: "20px", cursor: "pointer" }}  onClick={() => handlePrint(app.id, app.affiliation_type)} />
			  </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
      
      {filteredApplications.length === 0 && <p>No applications found.</p>}

      {filteredApplications.length > rowsPerPage && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              className={currentPage === index + 1 ? "active" : ""}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}
	  <div style={{ display: 'none' }} ref={printRef}>
	  {selectedApplicationId && <PreviewApplication id={selectedApplicationId} affiliation_code={SelectedAffiliationCode}/>}
      </div>
    </div>
    </div>
    
  );
};

export default ApplicationList;
