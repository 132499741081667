

import React, { useState, useEffect } from 'react';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';
import Captcha from './Captcha';
import './Captcha.css'; // Import CSS for styling
import { Register } from './LoginServices';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { UNIVERSITY_CODE } from '../../config/globals';
import { universityData } from './LoginServices';
import { useNavigate } from "react-router-dom";

const body = {
        backgroundColor: '#fff' 
}

const ApplicationForm = () => {
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [formValues, setFormValues] = useState({ firstname: '', middlename: '', lastname: '', email: '', mobile: '', dob: '', password: '', cpassword: '' });
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [universityDetails, setUniversityDetails] = useState({});
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [captchaKey, setCaptchaKey] = useState(Date.now());
    
    useEffect(() => {
        const fetchUniversityDetails = async () => {
              let payload = {
                    university_code: UNIVERSITY_CODE,
            };
            const response = await universityData(payload);
            if(response.data.status == 'failure') {
                alert(response.data.message);
              } else {
                setUniversityDetails(response.data.message[0]);
              }
          
        };
        fetchUniversityDetails();
      }, []);

    const handleInputChange = (event) => {
		const { name, value } = event.target;
        if (name === 'mobile') {
			const numericValue = value.replace(/[^0-9]/g, ''); 
			setFormValues({
				...formValues,
				[name]: numericValue,
			});
		} else {
			setFormValues({
				...formValues,
				[name]: value,
			});
		}
		
	};

    const handleCaptchaVerified = (isVerified) => {
        setCaptchaVerified(isVerified);
    };
    
    const validate = () => {
		let errors = {};
		if (!formValues.firstname) {
		  errors.firstname = 'Firstname is required';
		}
		
        if (!formValues.lastname) {
            errors.lastname = 'Lastname is required';
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formValues.email) {
            errors.email = 'Email is required';
        } else if (!emailRegex.test(formValues.email)) {
            errors.email = 'Invalid email format';
        }

        if (!formValues.mobile) {
            errors.mobile = 'Mobile is required';
        } else if (formValues.mobile.length !== 10) {
            errors.mobile = 'Mobile number must be 10 digits';
        }

        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!formValues.password) {
            errors.password = 'Password is required';
        } else if (!passwordRegex.test(formValues.password)) {
            errors.password =
                'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character';
        }

        if (!formValues.cpassword) {
            errors.cpassword = 'Confirm password is required';
        } else if (formValues.password != formValues.cpassword) {
            errors.cpassword = 'password and confirm password should match';
        }
		if (!captchaVerified) {
		  errors.captcha = 'Invalid Captcha';
		}
		setErrors(errors);
		return Object.keys(errors).length === 0;
	};

    const handleAlertClose = () => {
        setCaptchaKey(Date.now()); 
    };

    const onFinish = async() => {
        let payload = {
            first_name: formValues.firstname,
            middle_name: formValues.middlename,
            last_name: formValues.lastname,
            email_id: formValues.email,
            mobile: formValues.mobile,
            password: formValues.password,
            university_code: UNIVERSITY_CODE
        };
        try {
            let Response = await Register(payload);
            
            if(Response.data.status == 'failure') {
               alert(Response.data.message);
               handleAlertClose();
            } else {
                setShowModal(true);
            }
            
            
        } catch (error) {
            alert('An unexpected error occurred. Please try again later.');
            handleAlertClose();
        } 
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validate()) {
            setIsSubmitting(true);
            onFinish();
            setIsSubmitting(false);
        } 
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setFormValues({ firstname: '', middlename: '', lastname: '', email: '', mobile: '', dob: '', password: '', cpassword: '' });
        setErrors({});
        navigate('/applicant-login');
    };

    return (
        <>
        <div className='MainDiv' style={body}>
                <div className='AppltBg mb-5'>
                    <HomePageHeader universityDetails={universityDetails}/>  
                </div>
                <div className='containerfluid frmPoatRlvt'>
                    <div className='row'>
                        <div className='col-xs-12 col-lg-7'>  
                            <div className='pt-5 pb-3'>
                                <div className='untyTitle'>
                                    <h1>
                                        General Instructions 
                                    </h1>
                                </div>
                            </div>  
                            <div className='mt-5'>
                                <div className='tmcTitle pt-5'>
                                    <h1>
                                        The Candidates applying online may
                                    </h1>
                                </div>
                                <div className='tmcList'>
                                    <ul>
                                        <li>Read the brochure, eligibility criteria and availability of Seats Category-wise
                                            thoroughly before filling the application form.</li>
                                        <li>Keep all the information ready before starting to fill the Application Form.</li>
                                        <li>Keep ready the scanned (digital) images of your Photograph, Signature, 10th ,12th,
                                            Residence/Domicile and Caste Certificate (if applicable).</li>
                                        <li>OBC Candidates has to upload caste certificate as per norms of govt of Odisha 
                                            reservation.</li>
                                        <li>Please note your Application Number for future reference.</li>
                                        <li>Incomplete application form will be rejected.</li>
                                        <li>After completion of application form download the print application.</li>
                                    </ul>
                                </div>
                            </div>    
                        </div>
                        <div className='col-xs-12 col-lg-5'>
                            <div className='AllLgnBg'>
                                <div className='AppTitle'>
                                    <h1>Registration</h1>
                                </div>
                                <div className='p-2 mt-5'>
                                    <div className='col-xs-12 col-lg-12'>
                                        <div className='text-center'>
                                            <div className='lgnnvry'>
                                                <img src={require('../../img/formImg.png')}/>
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">First Name<span className='imp'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control fromFiles"
                                                        name="firstname"
                                                        id="firstname"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your First Name"
                                                        value={formValues.firstname}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.firstname && <span style={{ color: 'red' }}>{errors.firstname}</span>}
                                                </div>
                                            </div>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Middle Name</label>
                                                    <input
                                                        type="text"
                                                        className="form-control fromFiles"
                                                        name="middlename"
                                                        id="middlename"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Middle Name"
                                                        value={formValues.middlename}
                                                        onChange={handleInputChange}
                                                    />
                                                    
                                                </div>                    
                                            </div>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Last Name<span className='imp'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control fromFiles"
                                                        name="lastname"
                                                        id="lastname"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Last Name"
                                                        value={formValues.lastname}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.lastname && <span style={{ color: 'red' }}>{errors.lastname}</span>}
                                                </div>                    
                                            </div>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Email<span className='imp'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control fromFiles"
                                                        name="email"
                                                        id="email"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Email"
                                                        value={formValues.email}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.email && <span style={{ color: 'red' }}>{errors.email}</span>}
                                                </div>                    
                                            </div>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Mobile<span className='imp'>*</span></label>
                                                    <input
                                                        type="text"
                                                        className="form-control fromFiles"
                                                        name="mobile"
                                                        id="mobile"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Mobile"
                                                        value={formValues.mobile}
                                                        onChange={handleInputChange}
                                                        maxLength={10}
                                                    />
                                                    {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                                                </div>                    
                                            </div>
                                            <div className='col-xs-12 col-lg-6'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Password<span className='imp'>*</span></label>
                                                    <input
                                                        type="password"
                                                        className="form-control fromFiles"
                                                        name="password"
                                                        id="password"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Password"
                                                        value={formValues.password}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                                                </div>                    
                                            </div>
                                            <div className='col-xs-12 col-lg-12'>
                                                <div className="mb-4">
                                                    <label htmlFor="" className="formlabel">Confirm Password<span className='imp'>*</span></label>
                                                    <input
                                                        type="password"
                                                        className="form-control fromFiles"
                                                        name="cpassword"
                                                        id="cpassword"
                                                        aria-describedby="helpId"
                                                        placeholder="Enter your Password"
                                                        value={formValues.cpassword}
                                                        onChange={handleInputChange}
                                                    />
                                                    {errors.cpassword && <span style={{ color: 'red' }}>{errors.cpassword}</span>}
                                                </div>                    
                                            </div>
                                            
                                        </div>
                                        <div className='row mb-5'>
                                            <Captcha 
                                                key={captchaKey} 
                                                onCaptchaVerified={handleCaptchaVerified} 
                                            />
                                            {errors.captcha && <span style={{ color: 'red' }}>{errors.captcha}</span>}
                                        </div>
                                        
                                        <div className='row'>
                                            <div className='col-xs-12 col-lg-12 text-center'>
                                                <button
                                                    type="submit"
                                                    className="btn saveBtn widthBtn"
                                                    disabled={isSubmitting}
                                                >
                                                   {isSubmitting ? 'Submitting...' : 'Sign Up'}
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>        
            </div>
            <HomePageFooter universityDetails={universityDetails}/>

            {/* Modal for registration success message */}
            <Modal show={showModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Registration </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='modalp'>Registration was successful!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ApplicationForm
