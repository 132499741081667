import React, { useState, useEffect } from 'react';
import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';
import Loginvry from '../../img/userverification.png';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { sendOtp, forgotPassword} from './LoginServices';
import { universityData } from './LoginServices';
import { UNIVERSITY_CODE } from '../../config';

const ForgotPassword = () => {
    const [formValues, setFormValues] = useState({ mobilenumber: '', password: '', confirmpassword: '' });
    const [submitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [universityDetails, setUniversityDetails] = useState({});
    const [otpGenerated, setOtpGenerated] = useState(false); 

    useEffect(() => {
        const fetchUniversityDetails = async () => {
            let payload = {
                university_code: UNIVERSITY_CODE,
            };
            const response = await universityData(payload);
            if (response.data.status == 'failure') {
                alert(response.data.message);
            } else {
                setUniversityDetails(response.data.message[0]);
            }

        };
        fetchUniversityDetails();
    }, []);
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormValues({
            ...formValues,
            [name]: value,
        });
    };
    const validate = () => {
        let errors = {};
        const mobileOrEmailRegex = /^[0-9]{10}$/;
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!formValues.mobilenumber) {
            errors.mobile = 'Mobile or Email is required';
        } else if (!mobileOrEmailRegex.test(formValues.mobilenumber) && !emailRegex.test(formValues.mobilenumber)) {
            errors.mobile = 'Please enter a valid mobile number or email';
        }
        if (!formValues.password) {
            errors.password = 'Password is required';
        }
        if (formValues.password !== formValues.confirmpassword) {
            errors.ConfirmPassword = 'Passwords does not match';
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!otpGenerated) {
            setErrors({ otp: 'Please click on Generate OTP' });
            return; 
        }
        event.target.reset();

        if (validate()) {
            console.log('Form submitted successfully:', formValues);
        } else {
            console.log('Form has errors');
        }
        setSubmitting(true);
    };

    const finishSubmit = async () => {
        let response = await forgotPassword(formValues);
        if (response.data.status == 'failure') {
            alert(response.data.message);
        } else {
            setShowModal(true);
        }
    }

    useEffect(() => {
        if (Object.keys(errors).length === 0 && submitting) {
            finishSubmit()
        }
    }, [errors]);

    useEffect(() => {
        if (submitting) {
            validate()
        }
    }, [formValues]);

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleGenerateOtp = async () => {
        try {
            const response = await sendOtp({ mobilenumber: formValues.mobilenumber });
            if (response.data.status === 'success') {
                alert('OTP sent successfully!');
                setOtpGenerated(true);
            } else {
                alert(response.data.message);
                // setOtpGenerated(true);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            alert('Failed to send OTP. Please try again.');
        }
    };

    return (
        <>
            <div className='MainDiv'>
                <div className='AppltBg mb-5'>
                    <HomePageHeader universityDetails={universityDetails} />
                </div>
                <div className='containerfluid frmPoatRlvt'>
                    <div className='row'>
                        <div className='col-xs-12 col-lg-7'>
                            <div className='pt-5 pb-3'>
                                <div className='untyTitle'>
                                    <h1>General Instructions</h1>
                                </div>
                            </div>
                            <div className='mt-5'>
                                <div className='tmcTitle pt-5'>
                                    <h1>The Candidates applying online may</h1>
                                </div>
                                <div className='tmcList'>
                                    <ul>
                                        <li>Read the brochure, eligibility criteria and availability of Seats Category-wise thoroughly before filling the application form.</li>
                                        <li>Keep all the information ready before starting to fill the Application Form.</li>
                                        <li>Keep ready the scanned (digital) images of your Photograph, Signature, 10th, 12th, Residence/Domicile and Caste Certificate (if applicable).</li>
                                        <li>OBC Candidates have to upload caste certificate as per norms of govt of Odisha reservation.</li>
                                        <li>Please note your Application Number for future reference.</li>
                                        <li>Incomplete application form will be rejected.</li>
                                        <li>After completion of the application form download the print application.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className='col-xs-12 col-lg-5'>
                            <div className='AllLgnBg'>
                                <div className='AppTitle'>
                                    <h1>Forgot Password</h1>
                                </div>
                                <div className='p-2 mt-5'>
                                    <div className='col-xs-12 col-lg-12'>
                                        <div className='text-center'>
                                            <div className='lgnnvry'>
                                                <img src={Loginvry} alt="Login Verification" />
                                            </div>
                                        </div>
                                    </div>
                                    <form onSubmit={handleSubmit}>
                                        <div className='col-xs-12 col-lg-12'>
                                            <div className="mb-4">
                                                <label htmlFor="" className="form-label">Mobile/email<span className='imp'>*</span></label>
                                                <input
                                                    type="text"
                                                    className="form-control fromFiles"
                                                    name="mobilenumber"
                                                    id="mobilenumber"
                                                    aria-describedby="helpId"
                                                    placeholder="Enter your Mobile Number/Email"
                                                    onChange={handleInputChange}
                                                />
                                                {errors.mobile && <span style={{ color: 'red' }}>{errors.mobile}</span>}
                                            </div>
                                        </div>
                                        <div className='col-xs-12 col-lg-12'>
                                        <div className="mb-4">
                                            <label htmlFor="otp" className="form-label">OTP<span className='imp'>*</span></label>
                                            <div className="input-group">
                                                <input
                                                    type="text"
                                                    className="form-control fromFiles"
                                                    name="otp"
                                                    id="otp"
                                                    aria-describedby="helpId"
                                                    placeholder="Enter your OTP"
                                                    value={formValues.otp}
                                                    onChange={handleInputChange}
                                                />
                                                <button
                                                    type="button"
                                                    className="btn btn-primary"
                                                    onClick={handleGenerateOtp} 
                                                >
                                                    Generate OTP
                                                </button>
                                            </div>
                                            {errors.otp && <span style={{ color: 'red' }}>{errors.otp}</span>}
                                        </div>
                                </div>
                                <div className='col-xs-12 col-lg-12'>
                                    <div className="mb-4">
                                        <label htmlFor="" className="form-label">New Password<span className='imp'>*</span></label>
                                        <input
                                            type="password"
                                            className="form-control fromFiles"
                                            name="password"
                                            id="password"
                                            aria-describedby="helpId"
                                            placeholder="Enter your New Password"
                                            onChange={handleInputChange}
                                        />
                                        {errors.password && <span style={{ color: 'red' }}>{errors.password}</span>}
                                    </div>
                                </div>
                                <div className='col-xs-12 col-lg-12'>
                                    <div className="mb-4">
                                        <label htmlFor="" className="form-label">Confirm New Password<span className='imp'>*</span></label>
                                        <input
                                            type="password"
                                            className="form-control fromFiles"
                                            name="confirmpassword"
                                            id="confirmpassword"
                                            aria-describedby="helpId"
                                            placeholder="Confirm New Password"
                                            onChange={handleInputChange}
                                        />
                                        {errors.ConfirmPassword && <span style={{ color: 'red' }}>{errors.ConfirmPassword}</span>}
                                    </div>
                                </div>
                                <div className='row'>
                                    <div className='col-xs-12 col-lg-12 text-center'>
                                        <button
                                            type="submit"
                                            className="btn saveBtn widthBtn"
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div >
                <HomePageFooter universityDetails={universityDetails} />
                <Modal show={showModal} onHide={handleCloseModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>Set Password</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Your password has been set successfully!</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={handleCloseModal}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div >
        </>
    );
}

export default ForgotPassword;