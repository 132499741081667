import {HttpClient} from '../../../http-service/api';
import {API_LIST} from '../../../http-service/list';


export const GETAFFILIATIONAMOUNT = (payload) => {
    return HttpClient.post(API_LIST.GETAFFILIATIONAMOUNT,payload);
}

export const INITIATETRANSACTION = (payload) => {
    return HttpClient.post(API_LIST.INITIATETRANSACTION,payload);
}

export const PAYMENTGATEWAYLIST = (payload) => {
    return HttpClient.post(API_LIST.PAYMENTGATEWAYLIST,payload);
}


