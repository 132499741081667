import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from '../config';

// Create Axios instance
export const axios = Axios.create({
    baseURL: API_URL,
});

// Add an interceptor to include the token in the headers for every request
axios.interceptors.request.use(
    (config) => {
        // Get the token from sessionStorage or any other secure storage
        const token = sessionStorage.getItem('token');
        
        // If token exists, add it to the request headers
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        // Handle the error
        return Promise.reject(error);
    }
);
// Add a response interceptor to handle errors globally
axios.interceptors.response.use(
    (response) => {
        // If the response is successful, just return it
        return response;
    },
    (error) => {
        // Check if the response has an error and if it contains a response
        if (error.response) {
            // If the status code is 401 or 403, trigger logout
            if (error.response.status === 401 || error.response.status === 403) {
                handleLogout();
            }
        }
        // Reject the error to be handled locally in the component
        return Promise.reject(error);
    }
);

// Logout handler to clear session storage and redirect to login
const handleLogout = () => {
    sessionStorage.clear();  
    window.location.href="/";
};

export default axios;
