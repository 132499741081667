

import React from 'react'

const HomePageFooter = ({universityDetails}) => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div className='FtrBg'>
      Copyright © {currentYear} {universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'} All rights reserved.
      </div>
    </>
  )
}

export default HomePageFooter
