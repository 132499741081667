

import React, { useEffect, useState } from 'react';
import { Activeapplications } from './Activeapplications';
import Submittedapplications from './Submittedapplications';
import Notsubmittedapplications from './Notsubmittedapplications';
import Statuswiseapplications from './Statuswiseapplications';
import Applicationstatus from './Applicationstatus';
import { fetchApplicationCounts } from './DashboardServices';
import { APPLICATIONLIST } from './SuperAdmin/UniversityServices';

const Dashboard = () => {
  const [counts, setCounts] = useState(null); 
  const [loading, setLoading] = useState(true); 
  const [modalData, setModalData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchApplicationCounts(); 
        if(response.data.status == 'failure') {
          alert(response.data.message);
        } else {
          setCounts(response.data); 
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching counts:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleViewDetails = async(status) => {
		let payload = {
			status: status, 
			from_date: '' ,
			to_date: ''
		};
		const response = await APPLICATIONLIST(payload); 
		if(response.data.status == 'failure') {
			alert(response.data.message);
		} else {
			setModalData(response.data.message);
    		setIsModalOpen(true);
		}
    
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setModalData([]);
    setCurrentPage(1); 
  };

  const totalItems = modalData.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = modalData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };


  if (loading) {
    return <p>Loading...</p>; 
  }

  if (!counts) {
    return <p>No data available.</p>; 
  }
  return (
    <>
      <div className='row'>
          <Activeapplications count={counts.message.active_applications_count} onViewDetails={() => handleViewDetails('Active')} />    
          <Submittedapplications count={counts.message.submitted_applications_count} onViewDetails={() => handleViewDetails('Submitted')}/>
          <Notsubmittedapplications count={counts.message.not_submitted_applications_count} onViewDetails={() => handleViewDetails('NotSubmitted')} />  
          <Statuswiseapplications count={counts.message.year_wise_data}/> 
          <Applicationstatus count={counts.message}/>
      </div>

	  {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content view-model">
            <button className="close-modal" onClick={handleCloseModal}>
              &times;
            </button>
			<div className='AdinTitle'>
			<h1>Applications Details</h1>
			</div>
            
			<div className="table-responsive">
				<table className="application-table">
					<thead>
					<tr>
						<th>S.No</th>
						<th>Name</th>
						<th>Mobile No</th>
						<th>Email Id</th>
						<th>Affiliation Program</th>
						<th>Status</th>
					</tr>
					</thead>
					<tbody>
					{currentItems.map((app, index) => (
						<tr key={app.id}>
						<td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
						<td>{app.Applicant_Name || 'NA'}</td>
						<td>{app.Applicant_Mobile || 'NA'}</td>
						<td>{app.Applicant_Email || 'NA'}</td>
						<td>{app.affiliation_type || 'NA'}</td>
						<td>{app.appl_status || 'NA'}</td>
						</tr>
					))}
					</tbody>
				</table>
			</div>
			{modalData.length === 0 && <p>No applications found.</p>}

			{modalData.length > itemsPerPage && (
				<div className="pagination">
				{Array.from({ length: totalPages }, (_, index) => (
					<button
					key={index}
					className={currentPage === index + 1 ? "active" : ""}
					onClick={() => handlePageChange(index + 1)}
					>
					{index + 1}
					</button>
				))}
				</div>
			)}

          </div>
        </div>
      )}

    </>
  )
}

export default Dashboard
