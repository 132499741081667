import React, { useEffect, useState } from 'react';
import alogo from '../../img/alogo.png';
import ApplyIcon from '../../img/applyIcon.png';
import LginIcon from '../../img/loginIcon.png';
import { useNavigate } from 'react-router-dom';
import { FaUserPlus } from 'react-icons/fa';

const HomePageHeader = ({universityDetails}) => {
  const navigate = useNavigate();
  
  const LoginRedirection = (value) => {
    if (value === 'login') {
      navigate('/applicant-login');
    } else if (value === 'register') {
      navigate('/applicant-register');
    } else if (value === 'univlogin') {
      navigate('/university-login');
    }
  };

  return (
    <>
      <header className="py-5 containerfluid">
        <nav className="navbar navbar-expand-lg navbar-light bg-white Bx-shadow hmgPd">
          <div className="container-fluid">
            <a className="navbar-brand me-auto BrandLogo" href="/">
              
              <img src={universityDetails.logo_url ? universityDetails.logo_url : alogo}  style={universityDetails ? { } : { width: '230px'}} alt="Logo" />
              <span>{universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'}</span>
            </a>
            <div>
              <form className="d-flex">
                <button
                  className="btn loginBtn Hicons"
                  type="button" // Change from "submit" to "button"
                  onClick={() => LoginRedirection('login')}
                >
                  <span>
                    <img src={LginIcon} alt="login icon" />
                  </span>{' '}
                  Sign In
                </button>
                <button
                  className="btn siginBtn Hicons"
                  type="button" // Change from "submit" to "button"
                  onClick={() => LoginRedirection('register')}
                >
                  <span>
                    <img src={ApplyIcon} alt="register icon" />
                  </span>{' '}
                  Sign Up
                </button>
              </form>
            </div>
          </div>
        </nav>
      </header>
      <button className="adminLogin" onClick={() => LoginRedirection('univlogin')}>
        <FaUserPlus />
      </button>
    </>
  );
};

export default HomePageHeader;
