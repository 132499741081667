import React, { useState, useEffect } from 'react';
import AddUser from './AddUser';
import { GETUSERS, DELETEUSER } from './AdminServices';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const UsersList = () => {
  const username = sessionStorage.getItem('user');
  const [users, setUsers] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchUniversities = async () => {
      let response = await GETUSERS();
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        setUsers(response.data);
      }
    };
    fetchUniversities();
  }, []);

  const handleAddClick = () => {
    setIsModalOpen(true);
    setIsEditMode(false);
    setCurrentUser(null);
  };

  const handleEditClick = (user) => {
    setIsEditMode(true);
    setCurrentUser(user);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (userId) => {
    const confirmed = window.confirm('Are you sure you want to delete this user?');
    if (confirmed) {
      setUsers(users.filter((user) => user.id !== userId));
      let response = await DELETEUSER({id:userId});
      if(response.data.status == 'failure') {
        alert(response.data.message);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (formData) => {
    const newUser = {
      ...formData,
      id: isEditMode ? currentUser.id : users.length + 1,
    };

    if (isEditMode) {
      setUsers(users.map((user) => (user.id === currentUser.id ? newUser : user)));
    } else {
      setUsers([...users, newUser]);
    }

    setIsModalOpen(false);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUsers = users.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
      setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(users.length / itemsPerPage);

  return (
    <>
      <div className="pt-5">
        <div className="BxAllSide">
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <div className="BxApple p-6">
              
                <div className='row'>
                  <div className='col-12 col-lg-10'>
                  <div className="AdinTitle mb-4">
                      <h1>Users List</h1>
                  </div>
                  </div>
                  <div className='col-12 col-lg-2'>
                  <button onClick={handleAddClick} className="add-button">
                          Add User
                      </button>
                  </div>
                  </div>
                <div className="table-responsive">
                  <table className="application-table">
                    <thead>
                      <tr>
                        <th>SI.NO</th>
                        <th>First Name</th>
                        <th>Middle Name</th>
                        <th>Last Type</th>
                        <th>Email ID</th>
                        <th>Mobile</th>
                        <th>University Code</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUsers.map((user, index) => (
                        <tr key={user.id}>
                          <td>{indexOfFirstItem + index + 1}</td>
                          <td>{user.first_name}</td>
                          <td>{user.middle_name}</td>
                          <td>{user.last_name}</td>
                          <td>{user.email_id}</td>
                          <td>{user.mobile}</td>
                          <td>{user.university_code}</td>
                          <td>
                            <FaEdit style={{cursor:"pointer"}} onClick={() => handleEditClick(user)} />
                            <MdDelete style={{ width: "23px", marginLeft: "10px", cursor:"pointer" }} onClick={() => handleDeleteClick(user.id)} />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {users.length > itemsPerPage && (
                    <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                        <button
                            key={index}
                            className={`${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                        ))}
                    </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Conditionally render the modal based on isModalOpen */}
      {isModalOpen && (
        <AddUser
          isEditMode={isEditMode}
          currentUser={currentUser}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export default UsersList;
