// src/pages/affiliationSetup.js
import React, { useState, useEffect } from 'react';
import './AffiliationSetupPage.css';
import AffiliationForm from './AddAffiliationForm';
import { ADDAFFILIATIONS , UPDATEAFFILIATION, DELETEAFFILIATION, GETAFFILIATIONS } from './UniversityServices';
import { FaEdit } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const AffiliationSetup = () => {
    const [affiliations, setAffiliations] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [currentAffiliation, setCurrentAffiliation] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
     
    const fetchAffiliations = async () => {
        let response = await GETAFFILIATIONS();
        if(response.data.status == 'failure') {
            alert(response.data.message);
        } else {
            setAffiliations(response.data);
        }
    };
    
    useEffect(() => {
       
        fetchAffiliations();
        
    }, []);

    const handleAddClick = () => {
        setIsModalOpen(true);
        setIsEditMode(false);
        setCurrentAffiliation(null);
    };

    const handleEditClick =  (affiliation) => {
        setIsEditMode(true);
        setCurrentAffiliation(affiliation);
        setIsModalOpen(true);
    };  


    const handleDeleteClick = async (affiliationId) => {
        const confirmed = window.confirm('Are you sure you want to delete this affiliation?');
        if (confirmed) {
            setAffiliations(affiliations.filter((aff) => aff.id !== affiliationId));
            let response = await DELETEAFFILIATION({id:affiliationId});
            if(response.data.status == 'failure') {
                alert(response.data.message);
              }
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleFormSubmit = async (formData) => {
        try {
            if (isEditMode) {
                let response = await UPDATEAFFILIATION(formData);
                if(response.data.status == 'failure') {
                    alert(response.data.message);
                }
            } else {
                let response = await ADDAFFILIATIONS(formData);
                if(response.data.status == 'failure') {
                    alert(response.data.message);
                  }
            }

            fetchAffiliations();
            setIsModalOpen(false);
        } catch (error) {
            console.error("Error submitting form:", error);
            alert("Failed to save affiliation.");
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentAffiliations = affiliations.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const totalPages = Math.ceil(affiliations.length / itemsPerPage);

    return (
        <>
            <div className="pt-0">
                <div className="BxAllSide px-0 py-0">
                    <div className="row">
                        <div className="col-xs-12 col-lg-12">
                            <div className="BxApple p-6">
                                <div className='row'>
                                <div className='col-12 col-lg-11'>
                                <div className="AdinTitle mb-4">
                                    <h1>Affiliation Setup</h1>
                                </div>
                                </div>
                                <div className='col-12 col-lg-1'>
                                <button onClick={handleAddClick} className="add-button">
                                        Add
                                    </button>
                                </div>
                                </div>
                                
                                <div className="table-responsive">
                                    <table className="affiliation-table">
                                        <thead>
                                            <tr>
                                                <th>SI.NO</th>
                                                <th>Affiliation Name</th>
                                                <th>Affiliation Code</th>
                                                <th>Affiliation Description</th>
                                                <th>University Code</th>
                                                <th>Apply Start Date</th>
                                                <th>Apply End Date</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {currentAffiliations.map((affiliation, index) => (
                                                <tr key={affiliation.id}>
                                                    <td>{indexOfFirstItem + index + 1}</td>
                                                    <td>{affiliation.affiliation_name}</td>
                                                    <td>{affiliation.affiliation_code}</td>
                                                    <td>{affiliation.affiliation_desc}</td>
                                                    <td>{affiliation.university_code}</td>
                                                    <td>{affiliation.apply_start_date}</td>
                                                    <td>{affiliation.apply_end_date}</td>

                                                    <td>
                                                        {/*<button onClick={() => handleEditClick(affiliation)} className="edit-button" data-bs-target="#affiliationSetUp">Edit</button>
                                                        <button onClick={() => handleDeleteClick(affiliation.id)} className="delete-button"
                                                             data-bs-target="#affiliationSetUp">Delete</button>*/}
                                                        <FaEdit style={{cursor: "pointer"}} onClick={() => handleEditClick(affiliation)} />
                                                        <MdDelete style={{ width: "23px", marginLeft: "10px", cursor: "pointer"  }} onClick={() => handleDeleteClick(affiliation.id)} />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    
                                </div>

                                {affiliations.length > itemsPerPage && (
                                    <div className="pagination">
                                        {Array.from({ length: totalPages }, (_, index) => (
                                        <button
                                            key={index}
                                            className={`${currentPage === index + 1 ? 'active' : ''}`}
                                            onClick={() => handlePageChange(index + 1)}
                                        >
                                            {index + 1}
                                        </button>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Conditionally render the modal based on isModalOpen */}
            {isModalOpen && (
                <AffiliationForm
                    isEditMode={isEditMode}
                    currentAffiliation={currentAffiliation}
                    onClose={handleCloseModal}
                    onSubmit={handleFormSubmit}
                />
            )}
        </>
    );
};

export default AffiliationSetup;
