

import React from 'react'

const WorkflowManagement = () => {
  return (
    <>
      <div>
        Workflow Management
      </div>
    </>
  )
}

export default WorkflowManagement
