import React, { useEffect, useState } from "react";
import  ProceedPayment  from './ProceedPayment';
import { GETAFFILIATIONAMOUNT } from './PaymentServices'
import PreviewApplication from '../PreviewApplication';
import VerifiedApplication from "./VerifiedApplication";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content previewcontent">
        <button className="close-button" onClick={onClose}>X</button>
        {children}
      </div>
    </div>
  );
};


const PaymentPreview = () => {
    const [proceed,setProceed] = useState(false);
    const affiliationCode = sessionStorage.getItem('affiliation_code');
    const applicantStatus = sessionStorage.getItem('application_status');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handlePayment = () => {
      setProceed(true);
    };
    const [amount,setAmount] = useState(0);

    useEffect(()=>{
      const fetchAmount = async () => {
        let response = await GETAFFILIATIONAMOUNT({"affiliation_code":affiliationCode});
        setAmount(response.data.message[0].amount);
      };
      fetchAmount();
    },[]);

    const handlePreview = (applicant_id, affiliation_code) => {
      setIsModalOpen(true);
    };
    const closeModal = () => {
      setIsModalOpen(false);
    };

  return (
    <>
  {applicantStatus === "Verified" ? (
  <VerifiedApplication />
) : proceed ? (
  <ProceedPayment />
) : (
  <div style={styles.container}>
    <div style={styles.stepperContainer} className="col-xs-12 col-lg-12">
      <div className="text-right">{affiliationCode}</div>
    </div>

    <div style={styles.paymentInfo}>
      <p style={styles.note}>
        <strong>Note:</strong> Before payment, please click on the Preview
        Application button to view the Application and Proceed for Online
        Payment to Pay the amount.
      </p>
      <p style={styles.amount}>
        <strong>Amount:</strong> {amount}
      </p>
    </div>

    <div className="text-center mt-5">
      <button className="btn previousbtn" onClick={handlePreview}>
        Preview Application
      </button>
      <button className="btn saveBtn" onClick={handlePayment}>
        Proceed for Online Payment
      </button>
    </div>

    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <PreviewApplication />
    </Modal>
  </div>
)}
</>

  );
};

const styles = {
  container: {
    fontFamily: "'Arial', sans-serif",
    width: "70%",
    margin: "0 auto",
    textAlign: "center",
    /* border: "1px solid #ccc", */
    borderRadius: "8px",
    padding: "20px",
    /* boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", */
  },
  stepperContainer: {
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
    fontSize:'24px',
    fontWeight:'600',
    backgroundColor: "#f9f9f9",
    padding: "10px",
    borderRadius: "8px",
  },
  stepItemActive: {
    flex: 1,
    backgroundColor: "#90ee90",
    padding: "10px",
    borderRadius: "4px",
    margin: "0 10px",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  stepItemInactive: {
    flex: 1,
    backgroundColor: "#ffc107",
    padding: "10px",
    borderRadius: "4px",
    margin: "0 10px",
    color: "#fff",
    fontWeight: "bold",
    textAlign: "center",
  },
  badge: {
    backgroundColor: "#ffa500",
    color: "#fff",
    padding: "10px",
    borderRadius: "20px",
    fontWeight: "bold",
  },
  paymentInfo: {
    textAlign: "left",
  },
  note: {
    color: "red",
    marginBottom: "10px",
  },
  amount: {
    fontSize: "18px",
    marginBottom: "10px",
    textAlign: "center"
  },
  previewButton: {
    backgroundColor: "#ffa500",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    marginBottom: "20px",
  },
  paymentModeContainer: {
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
  },
  paymentModeLabel: {
    marginRight: "10px",
  },
  paymentModeInput: {
    flex: 1,
    height: "40px",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#f9f9f9",
  },
  proceedButton: {
    backgroundColor: "#35386D",
    color: "#fff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default PaymentPreview;
