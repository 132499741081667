// src/components/FormBuilder.js
import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import 'jquery-ui-dist/jquery-ui.css'; // jQuery UI styles
import './FormBuilder.css'; // Ensure formBuilder styles are loaded
import { INSERTFORM, UPDATEFORM } from './FormServices';

window.jQuery = $;
window.$ = $;

require('jquery-ui-sortable');
require('formBuilder');

const FormBuilder = ({ formData, onSave }) => {
  const [pages, setPages] = useState([]);
  const [activePageId, setActivePageId] = useState(1);
  const [formName, setFormName] = useState('');
  const fb = useRef(null);
  const [formBuilderInstance, setFormBuilderInstance] = useState(null);
  const isFormBuilderInitialized = useRef(false);

  useEffect(() => {
    if (formData) {
      setPages(formData.formData[0]?.pages || [{ id: 1, name: 'Page 1', formData: [] }]);
      setFormName(formData.name || '');
      setActivePageId(formData.activePageId || 1);
    }
  }, [formData]);

  useEffect(() => {
    if (fb.current && isFormBuilderInitialized.current) {
      const activePage = pages.find(page => page.id === activePageId);
      if (activePage) {
        $(fb.current).formBuilder('setData', activePage.formData);
      }
    } else if (fb.current) {
      initFormBuilder();
    }

    return () => {
      if (fb.current && $(fb.current).data('formBuilder')) {
        $(fb.current).formBuilder('destroy');
      }
    };
  }, [activePageId, pages, formData]);



  const initFormBuilder = () => {
    const activePage = pages.find(page => page.id === activePageId);
    if (activePage) {

      const typeUserAttrs = {
        text: {
          pattern: {
            label: 'Pattern (Regex)',
            value: '',
          }
        },
        customSelect: {
          subtype: {
            label: 'Subtype',
            value: '',
          }
        }
      };

      const typeUserDisabledAttrs = {
        customSelect: ['options', 'value'],  // Disabling the options and value attributes for customSelect
      };


      const fbOptions = {
        showActionButtons: false,
        formData: activePage.formData,
        fieldData: typeUserAttrs,
        typeUserAttrs: typeUserAttrs,  
        typeUserDisabledAttrs: typeUserDisabledAttrs,
        disableFields: ['autocomplete', 'file', 'button'], 
      };

      if (!isFormBuilderInitialized.current) {
        setFormBuilderInstance(
          $(fb.current).formBuilder(fbOptions)
        );
        isFormBuilderInitialized.current = true;
      } else {
        $(fb.current).formBuilder('setData', activePage.formData);
      }
    }
  };




  const saveFormData = () => {
    if (fb.current && $(fb.current).data('formBuilder')) {
      const activePage = pages.find(page => page.id === activePageId);
      if (activePage) {
        activePage.formData = $(fb.current).formBuilder('getData');
        setPages(pages.map(page => (page.id === activePage.id ? activePage : page)));
      }
    }
  };


  const handleAddPage = () => {
    saveFormData();
    const newPageId = pages.length + 1;
    const newPage = { id: newPageId, name: `Page ${newPageId}`, formData: [] };
    setPages([...pages, newPage]);
    setActivePageId(newPageId);
  };

  const handlePageChange = (pageId) => {
    saveFormData();
    setActivePageId(pageId);
  };

  const saveAll = async () => {
    saveFormData();
    console.log("pages",pages);
    const allData = pages.map(page => {
      return {
        id: page.id,
        name: page.name,
        formData: page.formData, 
      };
    });
    
    setActivePageId(activePageId);

    
    let payload = {
      form_name: formName,
      form_template: allData,
      created_by: "adm"
    };
    
    let UpdateFormPayload = {
      form_id: formData.id,
      form_template: allData
    }

    try {
      let response;
      if (formData.id) {
        response = await UPDATEFORM(UpdateFormPayload);
      } else {
        response = await INSERTFORM(payload);
      }
      if(response.data.status == "failure"){
        alert(response.data.message);
        return;
      }
      alert(response.data.message);
    } catch (error) {
      console.error('Error saving form:', error);
    }
    onSave({ id: formData.id, name: formName, formData: allData });

  };

  const handleFormNameChange = (event) => {
    setFormName(event.target.value);
  };

  useEffect(() => {
    console.log('FormData:', formData);
    console.log('Pages:', pages);
    console.log('ActivePageId:', activePageId);
  }, [formData, pages, activePageId]);


  return (
    <div className="">
      <div className="bg-white py-4">
        <div className="form-name mb-4">
          <label>
            Form Name: </label>
            <div className='clearfix'>
            <input
              type="text"
              value={formName}
              onChange={handleFormNameChange}
              className="form-name-input fromFilesclce"
              required
            />
         </div>
        </div>
        <div className="page-navigation mb-4">
          {pages.map(page => (
            <button
              key={page.id}
              className={`page-button ${activePageId === page.id ? 'active' : ''}`}
              onClick={() => handlePageChange(page.id)}
            >
              {page.name}
            </button>
          ))}
          
          <button className="add-page-button" onClick={handleAddPage}>
            Add Page
          </button>
          <div>
          <button className="add-button" onClick={saveAll}>
            Save All
          </button>
          </div>
        </div>
        <div id="fb-editor" ref={fb} />
      </div>
    </div>
  );
};

export default FormBuilder;
