import {HttpClient} from '../../../http-service/api';
import {API_LIST} from '../../../http-service/list';


export const GETFORMBYID = (payload) => {
    return HttpClient.post(API_LIST.GETFORMBYID,payload);
}

export const SAVEINSTITUTEDATA = (payload) => {
    return HttpClient.post(API_LIST.SAVEINSTITUTEDATA,payload);
}

export const GETINSTITUTEDATA = (payload) => {
    return HttpClient.post(API_LIST.GETINSTITUTEDATA,payload);
}

export const DELETETABLEDATA = (payload) => {
    return HttpClient.post(API_LIST.DELETETABLEDATA,payload);
}

export const GETDROPDOWNDATA = (payload) => {
    return HttpClient.post(API_LIST.GETDROPDOWNDATA, payload);
}
