import React, {useEffect, useState} from 'react';
import "../../SuperAdmin/AffiliationSetupPage.css";
import "../../../components/Register.css";
import Header from './Header';
import "../../Checkbox.css";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import TabbedFormRenderer from './TabbedFormRenderer';
import {GETFORMBYID} from './ProfileServices.jsx';
import { useParams, useLocation  } from 'react-router-dom'; 
import { universityData } from '../../LoginComponents/LoginServices';
import { UNIVERSITY_CODE } from '../../../config';


const ProfileComponent = () => {
    const { formId } = useParams();
    const [jsonData, setJsonData] = useState(null);
    const location = useLocation();
    const { applicationId, application_status,last_page, affiliation_code } = location.state || {};
    
    const [universityDetails, setUniversityDetails] = useState({});
   
  useEffect(() => {
    const fetchData = async () => {
        sessionStorage.setItem("application_status",application_status);
        sessionStorage.setItem("applicationId",applicationId);
        sessionStorage.setItem("affiliation_code",affiliation_code);
        try {
          let payload = {
           id : formId
        };
            const response = await GETFORMBYID(payload); 
            console.log("response",response.data.message[0].formData);
            
            setJsonData(response.data.message[0].formData);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();

    const fetchUniversityDetails = async () => {
        let payload = {
              university_code: UNIVERSITY_CODE,
      };
      const response = await universityData(payload);
      setUniversityDetails(response.data.message[0]);
    
  };
  fetchUniversityDetails();
},[]); 
useEffect(()=>{
    console.log("jsonData",jsonData);
},[jsonData])

    if (!jsonData) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <header>
                {!applicationId && <Header universityDetails={universityDetails}/>}
            </header>

            <TabbedFormRenderer jsonData={jsonData} applicationId={applicationId} application_status={application_status} last_page={last_page} affiliation_code={affiliation_code} />
        </div>
    )
}

export default ProfileComponent;
