import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PrivateRoute from './components/PrivateRoute';
import ProfileComponent from './components/InstituteComponents/ProfileComponents/ProfileComponent'
import ApplicationsList from './components/SuperAdmin/ApplicationsList';
import AffiliationList from './components/SuperAdmin/AffiliationList';
import GenericSetup from './components/SuperAdmin/GenericSetup';
import UsersList from './components/UsersList';
import Reports from './components/Reports';
import Dashboard from './components/Dashboard';
import WorkflowManagement from './components/WorkflowManagement';
import Layout from './components/Layout';
import AffiliationSetup from './components/SuperAdmin/AffiliationSetup';
import Home from './components/LoginComponents/Home';
import ApplicantLogin from './components/LoginComponents/ApplicantLogin';
import ApplicationForm from './components/LoginComponents/ApplicationForm';
import UniversityLogin from './components/LoginComponents/UniversityLogin';
import ChangePassword from './components/LoginComponents/changePassword';
import ForgotPassword from './components/LoginComponents/ForgotPassword';

import { SidebarProvider } from './components/SidebarContext';
import UniversitySetupPage from './components/SuperAdmin/UniversitySetupPage';
import ManageForms from './components/SuperAdmin/FormComponents/ManageForms';

import Unauthorized from './components/Unauthorized';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import HomeScreen from './components/InstituteComponents/HomeScreen';
import Process from './components/InstituteComponents/PaymentComponents/Process';
import PaymentStatus from './components/InstituteComponents/PaymentComponents/Status'


const App = () => {
  const [userRole, setUserRole] = useState(sessionStorage.getItem('role'));
  const updateUserRole = (role) => {
    sessionStorage.setItem('role', role);
    setUserRole(role);
  };
  return (
    <Router>
      <SidebarProvider>
        <Routes>
          {/* Public Routes */}
          <Route path='/' element={<Home />} />
          <Route path='/applicant-login' element={<ApplicantLogin updateUserRole={updateUserRole} />} />
          <Route path='/applicant-register' element={<ApplicationForm />} />
          <Route path='/university-login' element={<UniversityLogin updateUserRole={updateUserRole}/>} />
          <Route path='/change-password' element={<ChangePassword/>}/>
          <Route path='/forgot-password' element={<ForgotPassword/>}/>


          {/* Protected Routes */}
          <Route element={<Layout />}>
            <Route 
              path="/dashboard" 
              element={
                <PrivateRoute 
                  element={Dashboard} 
                  allowedRoles={['SUPADM', 'ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path='/university-setup' 
              element={
                <PrivateRoute 
                  element={UniversitySetupPage} 
                  allowedRoles={['SUPADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path='/manage-forms' 
              element={
                <PrivateRoute 
                  element={ManageForms} 
                  allowedRoles={['SUPADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/affiliation-list" 
              element={
                <PrivateRoute 
                  element={AffiliationList} 
                  allowedRoles={['ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/users-list" 
              element={
                <PrivateRoute 
                  element={UsersList} 
                  allowedRoles={['ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/applications-list" 
              element={
                <PrivateRoute 
                  element={ApplicationsList} 
                  allowedRoles={['SUPADM', 'ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/workflow-management" 
              element={
                <PrivateRoute 
                  element={WorkflowManagement} 
                  allowedRoles={['ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/reports" 
              element={
                <PrivateRoute 
                  element={Reports} 
                  allowedRoles={['ADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/affiliation-setup" 
              element={
                <PrivateRoute 
                  element={AffiliationSetup} 
                  allowedRoles={['SUPADM']} 
                  userRole={userRole} 
                />} 
            />
            <Route 
              path="/generic-setup" 
              element={
                <PrivateRoute 
                  element={GenericSetup} 
                  allowedRoles={['SUPADM']} 
                  userRole={userRole} 
                />} 
            />
          </Route>

          {/* Routes that don't use Layout */}
          <Route 
            path="/profile/:formId" 
            element={
              <PrivateRoute 
                element={ProfileComponent} 
                allowedRoles={['applicant', 'SUPADM', 'ADM']} 
                userRole={userRole} 
              />} 
          />
          <Route 
            path="/home" 
            element={
              <PrivateRoute 
                element={HomeScreen} 
                allowedRoles={['applicant']} 
                userRole={userRole} 
              />} 
          />

          <Route 
            path="/process" 
            element={
              <PrivateRoute 
                element={Process} 
                allowedRoles={['applicant', 'ADM']} 
                userRole={userRole} 
              />} 
          />

          <Route 
            path="/status/:amt/:status/:txnid" 
            element={
              <PaymentStatus/>} 
          />
         
          <Route path="/unauthorized" element={<Unauthorized />} />
        </Routes>
      </SidebarProvider>
    </Router>
  );
};

export default App;
