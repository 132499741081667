

import React, { useEffect, useState } from 'react';
import './homestyle.css';
import { universityData } from './LoginServices';
import { UNIVERSITY_CODE } from '../../config';


import HomePageHeader from './HomePageHeader';
import HomePageFooter from './HomePageFooter';
import HomePage from './HomePage';

const Home = () => {
  const [universityDetails, setUniversityDetails] = useState({});
    useEffect(() => {
        const fetchUniversityDetails = async () => {
              let payload = {
                    university_code: UNIVERSITY_CODE,
            };
            const response = await universityData(payload);
            if(response.data.status == 'failure') {
              alert(response.data.message);
            } else {
              setUniversityDetails(response.data.message[0]);
            }
          
        };
        fetchUniversityDetails();
      }, []);
  return (
    <>
   <HomePageHeader universityDetails={universityDetails}/>
   <HomePage universityDetails={universityDetails}/>   
   <HomePageFooter universityDetails={universityDetails}/>
    </>
  )
}

export default Home
