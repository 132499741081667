import {HttpClient} from '../../../http-service/api';
import {API_LIST} from '../../../http-service/list';


export const INSERTFORM = (payload) => {
    return HttpClient.post(API_LIST.INSERTFORM,payload);
}

export const GETFORMS = (payload) => {
    return HttpClient.get(API_LIST.GETFORMS,payload);
}

export const UPDATEFORM = (payload) => {
    return HttpClient.post(API_LIST.UPDATEFORM,payload);
}