import {HttpClient} from '../../http-service/api';
import {API_LIST} from '../../http-service/list';


export const GET_APPLICANT_DATA = (payload) => {
    return HttpClient.post(API_LIST.GET_APPLICANT_DATA,payload);
}

export const GET_DOCUMENT_UPLOAD_DATA = (payload) => {
    return HttpClient.post(API_LIST.GET_DOCUMENT_UPLOAD_DATA, payload);
}
export const UPLOADFILE = (payload) => {
    return HttpClient.post(API_LIST.UPLOADFILE, payload);
}
export const previewData = (payload) => {
    return HttpClient.post(API_LIST.PREVIEW_DATA,payload);
}