import React, { useState, useEffect } from 'react';
import './Captcha.css'; 

const generateRandomCaptcha = () => {
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let captcha = '';
  for (let i = 0; i < 6; i++) {
    captcha += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return captcha;
};

const Captcha = ({onCaptchaVerified}) => {
  const [captchaText, setCaptchaText] = useState(generateRandomCaptcha());
  const [inputValue, setInputValue] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    setInputValue('');
    setIsVerified(false);
  }, [captchaText]);

  const handleVerify = () => {
	
    if (inputValue === captchaText) {
      setIsVerified(true);
      onCaptchaVerified(true);
    } else {
      setIsVerified(false);
      onCaptchaVerified(false);
    }
  };

  const handleRefresh = () => {
	
    setIsVerified(false);
	onCaptchaVerified(false);
    const newCaptcha = generateRandomCaptcha();
    setCaptchaText(newCaptcha);
    
  };

  return (
   <>
		<div className='col-xs-12 col-lg-7'>
			<div>
				<label htmlFor="" className="formlabel">Captcha<span className='imp'>*</span></label>
				<input
					type="text"
					value={inputValue}
					onChange={(e) => setInputValue(e.target.value)}
					placeholder="Enter CAPTCHA"
					className="form-control fromFiles"
					onBlur={handleVerify}
				/>
			</div>
		</div>
		<div className='col-xs-4 col-lg-3'>
			<div className="MrgTop">
				<input
					type="text"
					className="form-control fromFilesCap"
					name=""
					id=""
					value={captchaText}
					aria-describedby="helpId"
					placeholder=""
				/>
			</div>
		</div>
		<div className='col-xs-4 col-lg-2'>
			<div className='refImg MrgTop'>
				<button type="button" onClick={handleRefresh} className="refresh-btn">
					<img src={require('../../img/refresh.png')} alt="Refresh CAPTCHA" />
				</button>
			</div>
		</div>
   </>
	
  );
};

export default Captcha;
