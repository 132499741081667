import React, { useState, useEffect } from 'react';
import './UniversitySetupPage.css';
import UniversityForm from './AddUniversityForm';
import { GETUNIVERSITIES, DELETEUNIVERSITY } from './UniversityServices';
import editicon from '../../img/edit-icon.png';
import deleteicon from '../../img/delete.png';

const UniversitySetupPage = () => {
  const username = sessionStorage.getItem('user');
  const [universities, setUniversities] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [currentUniversity, setCurrentUniversity] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  useEffect(() => {
    const fetchUniversities = async () => {
      let response = await GETUNIVERSITIES();
      if(response.data.status == 'failure') {
        alert(response.data.message);
      } else {
        setUniversities(response.data);
      }
    };
    fetchUniversities();
  }, []);

  const handleAddClick = () => {
    setIsModalOpen(true);
    setIsEditMode(false);
    setCurrentUniversity(null);
  };

  const handleEditClick = (university) => {
    setIsEditMode(true);
    setCurrentUniversity(university);
    setIsModalOpen(true);
  };

  const handleDeleteClick = async (universityId) => {
    const confirmed = window.confirm('Are you sure you want to delete this university?');
    if (confirmed) {
      setUniversities(universities.filter((uni) => uni.university_code !== universityId));
      let response = await DELETEUNIVERSITY({university_code:universityId});
      if(response.data.status == 'failure') {
        alert(response.data.message);
      }
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleFormSubmit = (formData) => {
    // Check for duplicates based on university_code
    const isDuplicate = universities.some(
      (uni) => uni.university_code === formData.university_code
    );
  
    if (isDuplicate) {
      alert("A university with this code already exists!");
      return;
    }
  
    const newUniversity = {
      ...formData,
      logo_url: formData.logo_url,
      bg_image_url: formData.bg_image_url,
      id: isEditMode ? currentUniversity.id : universities.length + 1,
    };
  
    if (isEditMode) {
      setUniversities(universities.map((uni) => (uni.id === currentUniversity.id ? newUniversity : uni)));
    } else {
      setUniversities([...universities, newUniversity]);
    }
  
    setIsModalOpen(false);
  };
  

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentUniversities = universities.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(universities.length / itemsPerPage);


  return (
    <>
      <div className="pt-0">
        <div className="BxAllSide px-0 py-0">
          <div className="row">
            <div className="col-xs-12 col-lg-12">
              <div className="BxApple p-6">
                <div className='row'>
                  <div className='col-12 col-lg-11'>
                  <div className="AdinTitle mb-4">
                  <h1>University Setup</h1>
                </div>
                  </div>
                  <div className='col-12 col-lg-1'>
                  <button onClick={handleAddClick} className="add-button">
                    Add
                  </button>
                  </div>
                </div>
                

                <div className="table-responsive">
                  <table className="university-table">
                    <thead>
                      <tr>
                        <th>SI.NO</th>
                        <th>University Name</th>
                        <th>University Code</th>
                        <th>University Type</th>
                        <th>Contact No</th>
                        <th>Logo</th>
                        <th>Location</th>
                        <th>University Email</th>
                        <th>University Address</th>
                        <th>Background Image</th>
                        <th>Created By</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUniversities.map((university, index) => (
                        <tr key={university.id}>
                          <td>{indexOfFirstItem + index + 1}</td>
                          <td>{university.university_name}</td>
                          <td>{university.university_code}</td>
                          <td>{university.university_type}</td>
                          <td>{university.contact_number}</td>
                          <td>
                            <img
                            
                              src={`${university.logo_url}`} 
                              alt={`${university.university_name} logo`}
                              width="50"
                            />
                          </td>
                          <td>{university.location}</td>
                          <td>{university.university_email}</td>
                          <td>{university.university_address}</td>
                          <td>
                            <img src={`${university.bg_image_url}`} style={{ width: "100%"}} alt={university.university_name} />
                          </td>
                          <td>{university.created_by}</td>
                          <td>
                            <div className='d-flex'>
                            {/* <button
                              onClick={() => handleEditClick(university)}
                              className="edit-button"
                            >
                              Edit
                            </button> */}
                            <img className='iconsw' src={editicon} alt="Edit Icon" onClick={() => handleEditClick(university)} />
                            <img className='iconsw' src={deleteicon} alt="Delete Icon" onClick={() => handleDeleteClick(university.university_code)} />
                            {/* <button
                              onClick={() => handleDeleteClick(university.id)}
                              className="delete-button"
                            >
                              Delete
                            </button> */}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  
                </div>
                {universities.length > itemsPerPage && (
                  <div className="pagination">
                    {Array.from({ length: totalPages }, (_, index) => (
                      <button
                        key={index}
                        className={`${currentPage === index + 1 ? 'active' : ''}`}
                        onClick={() => handlePageChange(index + 1)}
                      >
                        {index + 1}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Conditionally render the modal based on isModalOpen */}
      {isModalOpen && (
        <UniversityForm
          isEditMode={isEditMode}
          currentUniversity={currentUniversity}
          onClose={handleCloseModal}
          onSubmit={handleFormSubmit}
        />
      )}
    </>
  );
};

export default UniversitySetupPage;
