// src/Unauthorized.jsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/'); // Redirect to home or any other appropriate route
  };

  return (
    <div className="container text-center" style={{ marginTop: '50px' }}>
      <h1 className="display-4">Unauthorized Access</h1>
      <p className="lead">
        You do not have permission to view this page.
      </p>
      <button className="btn btn-primary" onClick={handleRedirect}>
        Go to Home
      </button>
    </div>
  );
};

export default Unauthorized;
