import React from "react";

const VerifiedApplication = () => {
  const affiliaionTitle = sessionStorage.getItem('affiliation_code');
  const amount = 1500;
  const transactionNo = "TRN685"
  const paymentDate = "01/12/2024";
  const paymentMode = "SBI";
  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3>Payment</h3>
      </div>
      <div style={styles.details}>
        <p>
          Thanks for your payment towards <strong>{affiliaionTitle}</strong>. The payment details are as follows:
        </p>
        <p>
          <strong>Amount:</strong> {amount}
        </p>
        <p>
          <strong>Payment Mode:</strong> {paymentMode}
        </p>
        <p>
          <strong>Transaction No:</strong> {transactionNo || "N/A"}
        </p>
        <p>
          <strong>Payment Date:</strong> {paymentDate || "N/A"}
        </p>
      </div>
      <div style={styles.footer}>
        <p style={styles.successMessage}>Payment has already been done.</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    border: "1px solid #f0a500",
    padding: "20px",
    maxWidth: "600px",
    margin: "20px auto",
    borderRadius: "5px",
    backgroundColor: "#fff8f2",
    fontFamily: "Arial, sans-serif",
  },
  header: {
    backgroundColor: "#f36c21",
    color: "#fff",
    padding: "10px",
    borderRadius: "5px 5px 0 0",
    textAlign: "center",
  },
  details: {
    padding: "20px",
  },
  footer: {
    textAlign: "center",
    marginTop: "20px",
  },
  successMessage: {
    color: "green",
    fontWeight: "bold",
  },
};

export default VerifiedApplication;
