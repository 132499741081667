import { UPDATE } from "react-admin"

const API_LIST = {
    LOGIN : 'login',
    Register: 'register',
    INSERTFORM : 'insert_form',
    UPDATEFORM : 'update_form',
    CHANGEPASSWORD : 'change_password',
    GETFORMS : 'get_forms',
    GETUNIVERSITIES : 'get_universities',
    ADDUNIVERSITY : 'add_university',
    UPDATEUNIVERSITY : 'update_university',
    DELETEUNIVERSITY : 'delete_university',
    GETAFFILIATIONS : 'get_affiliations',
    ADDAFFILIATIONS : 'add_affiliation',
    UPDATEAFFILIATION : 'update_affiliation',
    DELETEAFFILIATION : 'delete_affiliation',
    GETFORMBYID : 'get_form_by_id',
    SAVEINSTITUTEDATA:'save_institute_data',
    GETINSTITUTEDATA:'get_applicant_data',
    GET_APPLICANT_DATA: 'get_applicant_home_data',
    DELETETABLEDATA: 'delete_table_data',
    GET_DOCUMENT_UPLOAD_DATA:'get_required_documents',
    UPLOADFILE:'upload_file',
    PREVIEW_DATA: 'preview_data',
    GETUSERS: 'get_users',
    ADDUSER: 'add_user',
    DELETEUSER: 'delete_user',
    UPDATEUSER: 'update_user',
    FETCH_COUNTS: 'get_applicant_analytics',
    APPLICATIONLIST: 'get_applicant_list_on_status',
    GETTYPES : 'get_types',
    GETDROPDOWNDATA : 'get_dropdown_data_admin',
    DELETEDROPDOWNDATA : 'delete_dropdown_data',
    ADDDROPDOWNDATA : 'insert_dropdown_data',
    UPDATEDROPDOWNDATA : 'update_dropdown_data',
    UNIVERSITYDATA : 'university_data',
    GETREPORTS : 'get_reports',
    PAYMENTGATEWAYLIST : 'get_payment_gateway_list',
    GETAFFILIATIONAMOUNT : 'get_affiliation_amount',
    INITIATETRANSACTION : 'initiate_transaction',
    FORGOTPASSWORD : 'forgot_password',
    SENDOTP : 'send_otp'
}


export {API_LIST}

