import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { INITIATETRANSACTION } from "./PaymentServices";

const Process = () => {
  const { state } = useLocation();
  const { selectedGateway, amount } = state || {};
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const initiatePayment = async () => {
      if (!selectedGateway) {
        alert("No payment gateway selected.");
        return;
      }

      try {
        const initiation_response = await INITIATETRANSACTION({
          applicant_id: sessionStorage.getItem("application_id"),
          amount: amount,
          logged_in_user: sessionStorage.getItem("role"),
          pg_code: selectedGateway,
          deposit_status: "online",
        });
        setHtmlContent(initiation_response.data);
      } catch (error) {
        console.error("Error initiating payment:", error);
        alert("An error occurred. Please try again.");
      }
    };

    initiatePayment();
  }, [selectedGateway, amount]);

  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default Process;
