import React, { useState, useEffect, useRef } from 'react';
import { GET_DOCUMENT_UPLOAD_DATA, UPLOADFILE } from './HomeServices';
import { UNIVERSITY_CODE, API_URL } from '../../config';
import '../../components/Register.css'

const DocumentUpload = ({ applicationId, onSaveAndContinue}) => {
    const [documentFields, setDocumentFields] = useState([]);
    const [files, setFiles] = useState({});
    const application_id = applicationId;
    const [errors, setErrors] = useState({});
    const [previewImage, setPreviewImage] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const fileInputRefs = useRef([]);



    useEffect(() => {
        const fetchDocumentFields = async () => {
            try {
                const payload = {
                    "applicant_id": application_id,
                    "university_code": UNIVERSITY_CODE
                };
                const response = await GET_DOCUMENT_UPLOAD_DATA(payload);
                if (response.data.message) {
                    setDocumentFields(response.data.message);
                }
            } catch (error) {
                console.error("Error fetching document fields:", error);
            }
        };

        fetchDocumentFields();
    }, []);

    const handleFileChange = (index, event) => {
        const file = event.target.files[0];
        setFiles(prevFiles => ({
            ...prevFiles,
            [index]: file
        }));
    };

    const handleButtonClick = (index) => {
        if (fileInputRefs.current[index]) {
            fileInputRefs.current[index].click(); // Trigger file input click using ref
        } else {
            console.error(`File input for index ${index} is not defined.`);
        }
    };

    const validateFiles = () => {
        const newErrors = {};
        let isValid = true;

        documentFields.forEach((field, index) => {
            const file = files[index];

            if (field.document_path) {
                // If document_path exists, skip the required file check
                newErrors[index] = ""; // Clear any previous error for this field
            } else {
                // If no document_path, check if a file is selected
                if (!file) {
                    newErrors[index] = "File is required.";
                    isValid = false;
                }
            }
            if (file) {
                // Check file size
                const fileSizeInKB = file.size / 1024; // Convert bytes to KB
                if (fileSizeInKB > field.document_size_in_kb) {
                    newErrors[index] = `File size exceeds ${field.document_size_in_kb} KB.`;
                    isValid = false;
                }

                // Check file type
                const fileExtension = file.name.split('.').pop().toUpperCase();
                if (fileExtension !== field.document_extension_type.toUpperCase()) {
                    newErrors[index] = `File type must be ${field.document_extension_type}.`;
                    isValid = false;
                }
            }
        });

        setErrors(newErrors);
        return isValid;
    };
    const handleFileNameClick = (index) => {
        if (files[index]) {
            // If a file is selected, create a URL for the file and set it for preview
            const fileURL = URL.createObjectURL(files[index]);
            setPreviewImage(fileURL);
            setIsModalOpen(true);

        } else if (documentFields[index]?.document_path) {
            // If no file is selected but document_path exists, show the image from the server
            setPreviewImage(`${API_URL}/uploads/${documentFields[index].document_path}`);
            setIsModalOpen(true);

        } else {
            alert("No file chosen. Please select a file to preview.");
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setPreviewImage(null); // Clear the preview image when closing
    };

    const handleSaveDocuments = async () => {
        if (!validateFiles()) {
            return; // Stop if validation fails
        }
        const formData = new FormData();
        formData.append('university_code', UNIVERSITY_CODE);
        formData.append('logged_in_user', "applicant");
        formData.append('applicant_id', application_id);
        Object.keys(files).forEach(index => {
            const file = files[index];
            if (file) {
                const documentTypeCode = documentFields[index]?.document_type_code;
                formData.append(documentTypeCode, file);
            }
        });

        try {
            const response = await UPLOADFILE(formData);
            if (response.data.status == "success") {
                alert("Documents Uploaded succesfully");
                onSaveAndContinue();
            } else {
                alert(response.data.message)
            }
        } catch (error) {
            console.error("Error uploading files:", error);
        }
    };

    return (
        <>

            <div className='row'>
                <div className="col-xs-12 col-lg-12">
                    <div className="tab-heading mb-3">
                        <h1>
                            Upload Documents
                        </h1>
                    </div>
                    <hr />
                </div>
            </div>
            <div className="row mt-5">
                {documentFields.map((field, index) => (
                    <div className="col-xs-12 col-lg-4 mb-5" key={index}>
                        <label htmlFor={`formFile${index}`} className="formlabel">{field.document_type}</label>
                        <span
                            style={{
                                color: 'red',
                                fontStyle: 'italic',
                                fontSize: 'small',
                                display: 'block',  // Ensures the span is displayed as a block element below the label
                                marginTop: '5px'  // Optional: adds a little space between label and span
                            }}
                        >
                            {' *Max: '}{field.document_size_in_kb}{'kb, Supported: '}{field.document_extension_type}{' only'}
                        </span>
                        <div className="inputBord drklight">
                            <input
                                className="input-file"
                                id={`formFile${index}`}
                                type="file"
                                onChange={(event) => handleFileChange(index, event)}
                                style={{ display: 'none' }}
                                ref={el => fileInputRefs.current[index] = el}
                            />
                            <button
                                className="btn btn-light form-control-lg me-2"
                                onClick={() => handleButtonClick(index)}
                            >
                                Choose File
                            </button>
                            <span onClick={() => handleFileNameClick(index)} style={{ cursor: 'pointer', color: '#35386D' }}>
                                {files[index] ? (
                                    files[index].name
                                ) : (
                                    field.document_path ? (
                                        field.document_path
                                    ) : (
                                        'No File Choosen'
                                    )
                                )}
                            </span>
                            {errors[index] && <div className="text-danger">{errors[index]}</div>}

                        </div>
                    </div>
                ))}
            </div>
            <div className="text-center mt-4">
                <button className="btn saveBtn" onClick={handleSaveDocuments}>
                    Save and Continue
                </button>
            </div>
            {isModalOpen && (
                <div className="modal" style={{ display: 'block', position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
                    <div className="modal-content" style={{ position: 'relative', margin: 'auto', padding: '20px', backgroundColor: 'white', borderRadius: '5px', maxWidth: '600px' }}>
                        <span onClick={closeModal} style={{ position: 'absolute', top: '10px', right: '15px', cursor: 'pointer', fontSize: '20px' }}>&times;</span>
                        {previewImage && previewImage.endsWith(".pdf") ? (
                <iframe
                    src={previewImage}
                    width="100%"
                    height="500px"
                    title="PDF Preview"
                    style={{ border: 'none' }}
                />
            ) : (
                <img
                    src={previewImage}
                    alt="Preview"
                    className="img-preview"
                    style={{ width: '100%', height: 'auto', borderRadius: '5px' }}
                />
            )}
                    </div>
                </div>
            )}
        </>
    )
}


export default DocumentUpload;

