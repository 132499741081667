// src/components/PrivateRoute.js
import React from 'react';
import { Route, Navigate } from 'react-router-dom';

// Helper function to check if user is authenticated
const isAuthenticated = () => {
  const token = sessionStorage.getItem('token');
  return !!token;  // Returns true if token exists, false otherwise
};

// PrivateRoute component to protect routes
const PrivateRoute = ({ element: Component, allowedRoles, userRole }) => {
  const authenticated = isAuthenticated();
  console.log(allowedRoles)
  // Check if user is authenticated and has the correct role
  if (authenticated && allowedRoles.includes(userRole)) {
    return <Component />;
  } else if (!authenticated) {
    // Redirect to login if not authenticated
    return <Navigate to="/" replace />;
  } else {
    // Redirect to an unauthorized page if authenticated but role is not allowed
    return <Navigate to="/unauthorized" replace />;
  }
};

export default PrivateRoute;
