import React, { useState, useEffect } from 'react';
import { GETTYPES } from './UniversityServices';
import CrudOperations from './CrudOperations';
import './GenericSetup.css';

const GenericSetup = () => {
  const [selectedType, setSelectedType] = useState('');
  const [data, setData] = useState([]);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [newOption, setNewOption] = useState(''); // State for the input field when adding a new option

  // Fetch dropdown options from the API
  useEffect(() => {
    const fetchDropdownOptions = async () => {
      try {
        const response = await GETTYPES();
        if(response.data.status == 'failure') {
          alert(response.data.message);
        } else {
          setDropdownOptions(response.data);
        }
      } catch (error) {
        console.error('Error fetching dropdown options:', error);
      }
    };

    fetchDropdownOptions();
  }, []);

  // Handle type selection change
  const handleTypeChange = (e) => {
    setSelectedType(e.target.value);
    setData([]);
  };

  // Handle input change for adding a new option
  const handleInputChange = (e) => {
    setNewOption(e.target.value);
  };

  // Handle adding a new option to the dropdown
  const handleAddOption = () => {
    if (newOption.trim() !== '') {
      const newOptionObj = { type: newOption };
      setDropdownOptions([...dropdownOptions, newOptionObj]);
      setSelectedType(newOption); // Select the new option
      setNewOption(''); // Reset input field after adding
    } else {
      alert('Enter New Type.');
    }
  };

  return (
    <div className='BxAllSide px-0 py-0'>
      <div className="BxApple p-6 AdinTitle">
        <h1>Generic Setup</h1>
        
        <div className="align-items-center">
          <div className='row'>
            <div className='col-12 col-lg-3'>
            <select 
            className="form-select all-dropselect mt-5"
            onChange={handleTypeChange}
            value={selectedType}
          >
            <option value="">Select Type</option>
            {dropdownOptions.map((option, index) => (
              <option key={option.type || index} value={option.type}>
                {option.type.charAt(0).toUpperCase() + option.type.slice(1)}
              </option>
            ))}
            <option value="add">Add New</option> {/* Add the "Add New" option */}
          </select>
            </div>
            <div className='col-12 col-lg-6'>
            {selectedType === 'add' && (
            <div className="mt-5 row">
              <div className='col-6'>
              <input
                type="text"
                className="form-control fromFilesclce"
                placeholder="Enter new type"
                value={newOption}
                onChange={handleInputChange}
              />
              </div>              
              <div className='col-4'>
              <button
                className="add-button"
                onClick={handleAddOption}
              >
                Add New Type
              </button>
              </div>              
            </div>
          )}
            </div>
            
          </div>
          

         
        </div>

        {/* Show the CrudOperations component if a type is selected */}
        {selectedType && selectedType !== 'add' && (
          <CrudOperations selectedType={selectedType} />
        )}
      </div>
    </div>
  );
};

export default GenericSetup;
