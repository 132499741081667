import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useSidebar } from './SidebarContext';


const Layout = () => {
  const { isOpen } = useSidebar();
  return (
    <div className="main-container">
      <Sidebar />
      <div className={`content ${isOpen ? 'active-cont' : ''}`}>
        <Outlet /> {/* This is where the routed components will be rendered */}
      </div>
    </div>
  );
};

export default Layout;
