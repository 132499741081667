

import React from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Visibility, Edit } from '@mui/icons-material';
import viewicon from '../../img/view-icon.png';
import editicon from '../../img/edit-icon.png';

const AffiliationList = () => {
  const handleViewClick = (id) => {
    // Implement the logic to handle the view action
    console.log(`View details for ID: ${id}`);
    // You can navigate to a detail page or open a modal with the details
  };

  const handleEditClick = (id) => {
    // Implement the logic to handle the edit action
    console.log(`Edit details for ID: ${id}`);
    // You can navigate to an edit page or open a modal with the edit form
  };

  const rows = [
    { id: 'ID-326587', name: 'Dwight', phone: '9988771245', email: 'dwight@gmail.com', program: 'dwight', status: 'Done', city: 'Hyderabad', state: 'Telangana' },
    { id: '3', name: 'Dwight', phone: '9988771245', email: 'dwight@gmail.com', program: 'dwight', status: 'Done', city: 'Hyderabad', state: 'Telangana' },
    { id: '3', name: 'Dwight', phone: '9988771245', email: 'dwight@gmail.com', program: 'dwight', status: 'Done', city: 'Hyderabad', state: 'Telangana' }
  ];

  return (
    <>
      <div>
        <div className='BxAllSide'>
          <div className='row'>
            <div className='col-xs-12 col-lg-12'>
              <div className='BxApple p-6'>
                <div className='AdinTitle mb-4'>
                  <h1>Total Affiliation List</h1>
                </div>
                <div className='table-responsive'>
                  <TableContainer component={Paper}>
                    <Table className="adminTable">
                      <TableHead>
                        <TableRow>
                          <TableCell>Institute Id</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Phone Number</TableCell>
                          <TableCell>Email id</TableCell>
                          <TableCell>Program</TableCell>
                          <TableCell>Status</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>Print Application</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell>{row.id}</TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.phone}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.program}</TableCell>
                            <TableCell>{row.status}</TableCell>
                            <TableCell>{row.city}</TableCell>
                            <TableCell>{row.state}</TableCell>
                            <TableCell>Print</TableCell>
                            <TableCell>
                              <div className="d-flex">
                                <IconButton className="MrgImg" onClick={() => handleViewClick(row.id)}>
                                  <Visibility />
                                </IconButton>
                                <IconButton onClick={() => handleEditClick(row.id)}>
                                  <Edit />
                                </IconButton>
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className='row'>
                  <div className='col-xs-12 col-lg-12 mt-5 mb-4 text-center'>
                    <button type="button" className="btn saveBtn">Save</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default AffiliationList
