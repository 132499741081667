import React, { useState, useEffect } from 'react';
import { ADDUSER, UPDATEUSER } from './AdminServices';

const AddUser = ({ isEditMode, currentUser, onClose, onSubmit }) => {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email_id: '',
    mobile: '',
    password: '',
    user_name: '',
    role: '',
    created_by: sessionStorage.getItem('user'),
    university_code: ''
  });

  useEffect(() => {
    if (isEditMode && currentUser) {
      setFormData({
        first_name: currentUser.first_name || '',
        middle_name: currentUser.middle_name || ' ',
        last_name: currentUser.last_name || '',
        email_id: currentUser.email_id || '',
        mobile: currentUser.mobile || '',
        password: currentUser.password || '',
        user_name: currentUser.user_name || '',
        role: currentUser.role || '',
        id: currentUser.id,
        university_code: currentUser.university_code || '',
      });
    }
  }, [isEditMode, currentUser]);

  const validateEmail = (value) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(value) ? '' : 'Invalid email address.';
  };

  const validateMobile = (value) => {
    const mobilePattern = /^[0-9]{10}$/;
    return mobilePattern.test(value) ? '' : 'Invalid mobile number. It must be 10 digits.';
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Update form data
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      user_name:
        name === 'first_name' || name === 'last_name'
          ? `${name === 'first_name' ? value : prevData.first_name}_${name === 'last_name' ? value : prevData.last_name}`
          : prevData.user_name,
    }));

    // Perform validations
    if (name === 'email_id') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email_id: validateEmail(value),
      }));
    }
    if (name === 'mobile') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        mobile: validateMobile(value),
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate fields
    const emailError = validateEmail(formData.email_id);
    const mobileError = validateMobile(formData.mobile);
    const newErrors = { email_id: emailError, mobile: mobileError };

    setErrors(newErrors);

    if (Object.values(newErrors).some((error) => error)) {
      alert('Please fix the errors before submitting.');
      return;
    }

    // Call the parent component's onSubmit function
    onSubmit(formData);

    try {
      const response = isEditMode
        ? await UPDATEUSER(formData)
        : await ADDUSER(formData);

      if (response.data.status === 'failure') {
        alert(response.data.message);
      } else {
        alert(isEditMode ? 'User successfully updated.' : 'User successfully added.');
        onClose();
      }
    } catch (err) {
      alert('An error occurred while submitting the form. Please try again.');
    }
  };

  return (
    <div className="modal show" style={{ display: 'block' }}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content py-0 px-0">
          <div className="modal-header modelheader">
            <h5 className="modal-title modaltitle">
              {isEditMode ? 'Edit User' : 'Add User'}
            </h5>
            <button type="button" className="btn-close btnclose" onClick={onClose}>×</button>
          </div>
          <div className="modal-body model-popupPad">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">First Name<span className="imp">*</span></label>
                  <input
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                    className="form-control fromFilespop"
                    required
                  />
                </div>
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">Middle Name</label>
                  <input
                    type="text"
                    name="middle_name"
                    value={formData.middle_name}
                    onChange={handleInputChange}
                    className="form-control fromFilespop"
                  />
                </div>
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">Last Name<span className="imp">*</span></label>
                  <input
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                    className="form-control fromFilespop"
                    required
                  />
                </div>
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">Email ID<span className="imp">*</span></label>
                  <input
                    type="email"
                    name="email_id"
                    value={formData.email_id}
                    onChange={handleInputChange}
                    className={`form-control fromFilespop ${errors.email_id ? 'is-invalid' : ''}`}
                    required
                  />
                  {errors.email_id && <div className="text-danger">{errors.email_id}</div>}
                </div>
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">Mobile<span className="imp">*</span></label>
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleInputChange}
                    className={`form-control fromFilespop ${errors.mobile ? 'is-invalid' : ''}`}
                    required
                  />
                  {errors.mobile && <div className="text-danger">{errors.mobile}</div>}
                </div>
                {!isEditMode && (
                  <>
                    <div className="col-12 col-lg-4 mb-4">
                      <label className="formlabel">Password<span className="imp">*</span></label>
                      <input
                        type="password"
                        name="password"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="form-control fromFilespop"
                        required
                      />
                    </div>
                    <div className="col-12 col-lg-4 mb-4">
                      <label className="formlabel">Role<span className="imp">*</span></label>
                      <input
                        type="text"
                        name="role"
                        value={formData.role}
                        onChange={handleInputChange}
                        className="form-control fromFilespop"
                        required
                      />
                    </div>
                  </>
                )}
                <div className="col-12 col-lg-4 mb-4">
                  <label className="formlabel">University Code<span className="imp">*</span></label>
                  <input
                    type="text"
                    name="university_code"
                    value={formData.university_code}
                    onChange={handleInputChange}
                    className="form-control fromFilespop"
                    required
                  />
                </div>
              </div>

              <div className="text-center mt-mb">
                <button type="submit" className="btn updatebtn">
                  {isEditMode ? 'Update User' : 'Add User'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
