import React, { useState } from "react";
import { Loading } from "react-admin";
import { useParams } from "react-router-dom";

const PaymentStatus = () => {
  const{amt,status,txnid} = useParams();
  const isSuccess = status.toLowerCase() === "success";
  const [initiation,setInitiation] = useState(false);
  return (
    <>
    {initiation?(<><Loading/ ></>):(<div style={{ border: "1px solid #ccc", padding: "20px", maxWidth: "400px", margin: "20px auto" }}>
      <h2>{'Jawaharlal Nehru Technological University'}</h2>
      <p>
        <strong>Payment Status:</strong> <span style={{ color: isSuccess ? "green" : "red" }}>{status}</span>
      </p>
      <p>
        <strong>Payment ID:</strong> {txnid || "N/A"}
      </p>
      {isSuccess && (
        <p>
          <strong>Amount Paid:</strong> {amt}
        </p>
      )}
      {isSuccess ? (
        <a href="/home" style={{ color: "blue", textDecoration: "underline" }}>
          Click Here to continue to the application form.
        </a>
      ) : (
        <p>Please retry your payment or contact support for assistance.</p>
      )}
    </div>)}
    </>
  );
};

export default PaymentStatus;
