import React from 'react';
import { Line } from "react-chartjs-2";

const Statuswiseapplications = ({count}) => {
  console.log(count);

  const lineChartData = {
    labels: count.map((data) => data.year), 
    datasets: [
      {
        label: "Pending Applications",
        data: count.map((data) => data.pending_applications), 
        borderColor: "orange",
        backgroundColor: "rgba(255,165,0,0.2)",
        borderWidth: 2,
        tension: 0.4,
      },
      {
        label: "Submitted Applications",
        data: count.map((data) => data.submitted_applications),
        borderColor: "rgba(75, 192, 192, 1)",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderWidth: 2,
        tension: 0.4, 
      }
    ],
  };
  const lineChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        onClick: null, // Disables label click action
      },
    },
    interaction: {
      mode: "index",
      intersect: false, // Allows tooltip without requiring a click
    },
  };
  return (
    <>
       <div className='col-xs-12 col-lg-8 '>
          <div className='BxApple'>
            <div className='bxTitle'>
              <h1>
                Status Wise Applications
              </h1>
            </div>
            <hr />
            <div className="BxWiseGrap">
              <Line data={lineChartData} options={lineChartOptions} />
            </div>
          </div>
        </div>
    </>
  )
}

export default Statuswiseapplications
