// src/components/ManageForms.js
import React, { useState,useEffect } from 'react';
import FormBuilder from './FormBuilder';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';
import {GETFORMS} from './FormServices';
import { FaSearch, FaEdit } from "react-icons/fa";
import back from '../../../img/backarrow.png';



const ManageForms = () => {
  const [showFormBuilder, setShowFormBuilder] = useState(false);
  const [formToEdit, setFormToEdit] = useState(null);
  const [forms, setForms] = useState([]);
  
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const response = await GETFORMS();
        if(response.data.status == "failure"){
          alert(response.data.message);
          return;
        }
        setForms(response.data.message || []); 
      } catch (err) {
        console.error('Error fetching forms:', err);
      }
    };

    fetchForms();
  }, []);


  const handleCreateForm = () => {
    setShowFormBuilder(true);
    setFormToEdit({
      id: '', 
      name: '', 
      formData: { pages: [{ id: 1, name: 'Page 1', formData: [] }] }
    });  };

  const handleEditForm = (form) => {
    if (form.formData && !form.formData[0]?.pages) {
      form.formData = [{
        pages: form.formData
      }];
    }
    console.log("form",form);
    setFormToEdit(form);
    setShowFormBuilder(true);
  };


  const handleSaveForm = (formData) => {
    if (formData.id) {
      setForms(forms.map((form) => (form.id === formData.id ? formData : form)));
    } else {
      setForms([...forms, { ...formData, id: forms.length + 1 }]);
    }
    setShowFormBuilder(false); 
  };


  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentForms = forms.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(forms.length / itemsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const handleBackClick = () => {
    setShowFormBuilder(false);  // Hide the form builder and show the list again
  };

  return (
    <div>
      <div className='BxAllSide px-0 py-0'>
      <div className="BxApple p-6 application-list-container">
        
        <div className='row'>
          <div className='col-12 col-lg-10'>
          <div className='AdinTitle' >
          {showFormBuilder && (
        <button className="back-arrow-btn" style={{ marginRight: '5px', marginLeft: '-10px' }} onClick={handleBackClick}>
            <span><img src={back} alt="Back" /></span>
        </button>
           )}
        	<h1>{!showFormBuilder ? 'Forms List' : formToEdit.id ? 'Edit Form' : 'Create Form'}</h1>
        </div>
          </div>
		  {!showFormBuilder &&
          <div className='col-12 col-lg-2'>
          <button className="formbtn" onClick={handleCreateForm}>Create Form</button>
          </div>}
        </div>
        <div className='row'>
          <div className='col-xs-12 col-lg-12'>
            <div>
              {!showFormBuilder ? (
                <>
                  
                  <div style={{ marginBottom: '20px' }} />
                  <TableContainer component={Paper}>
                  <Table className="formtable" style={{ borderCollapse: 'collapse' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell >S.No</TableCell>
                        <TableCell>Form Name</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentForms.map((form, index) => (
                        <TableRow key={form.id}>
                          <TableCell>{indexOfFirstItem + index + 1}</TableCell>
                          <TableCell>{form.name}</TableCell>
                          <TableCell>
                              <FaEdit style={{cursor: "pointer"}} onClick={() => handleEditForm(form)} />
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  </TableContainer>

                  {forms.length > itemsPerPage && (
                      <div className="pagination">
                        {Array.from({ length: totalPages }, (_, index) => (
                          <button
                            key={index}
                            className={`${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                          >
                            {index + 1}
                          </button>
                        ))}
                      </div>
                    )}
                </>
              ) : (
                <FormBuilder formData={formToEdit} onSave={handleSaveForm}/>
              )}
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ManageForms;
