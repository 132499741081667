
import React from 'react';
import RightIcon from '../../img/checked.png';

const HomePage = ({universityDetails}) => {
          
  return (
    <>
      <div className='container-xxl'>
    <div className='p-3 mb-5'>
    <div className='row'>
        <div className='col-xs-12 col-lg-5'>
            <div className='unvisity' style={universityDetails.bg_image_url ? {backgroundImage: `url(${universityDetails.bg_image_url})`} : {}}>
                
            </div>
        </div>
        <div className='col-xs-12 col-lg-7'>
            <div className='p-4 text-center'>
                <div className='col-xs-12 col-lg-12'>
                    <div className='unvisityTitle unvisityMt'>
                        <h1>
                         Welcome to University
                        </h1>
                    </div>
                </div>
                <div className='col-xs-12 col-lg-12'>
                    <div className='unvisityTitle mb-2'>
                        <h2>
                        {universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'}
                        </h2>
                    </div>
                </div>
              {/*   <div className='col-xs-12 col-lg-12'>
                    <div className='unvisityTitle mb-4'>
                        <p>
                        (Bihar)
                        </p>
                    </div>
                </div> */}
               
               {/*  <div className='col-xs-12 col-lg-12'>
                    <div className='unvisityTitleAd mt-4'>
                        <p>
                            07/08/2022 is the last date of PG, UG And VOCATIONAL Admission.
                        </p>
                    </div>
                </div> */}
            </div>            
        </div>   
        </div>
        <div className='row mt-5'>
            <div className='col-xs-12 col-lg-12'>
                <div className='infsBg'>
                    <div className='instBgBar mb-4'>
                        <div className='instBgBar'>
                        Instruction for Colleges
                        </div>
                    </div>
                    <div className='col-xs-12 col-lg-12'>
                        <div className='p-3'>
                            <div className='rightIcon d-flex'>
                           <span><img src={RightIcon}/> </span> <p>Lorem ipsum dolor sit amet, consectetur adipiscing eli.</p>
                            </div>
                            <div className='rightIcon d-flex'>
                            <span><img src={RightIcon}/> </span><p>Lorem ipsum dolor sit amet, consectetur adipiscing eli.</p>
                            </div>
                            <div className='rightIcon d-flex'>
                            <span><img src={RightIcon}/> </span><p>Lorem ipsum dolor sit amet, consectetur adipiscing eli.</p>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
   </div>
    </>
  )
}

export default HomePage
