import React from 'react';
import alogo from '../../img/alogo.png';
import ApplyIcon from '../../img/applyIcon.png';
import LginIcon from '../../img/loginIcon.png';
import { useNavigate } from "react-router-dom";
import usericon from '../../img/usericon.png';



const Navbar = ({ username, universityDetails }) => {

  const navigate = useNavigate();
  const handleLogout = () => {
    sessionStorage.clear();
    navigate('/applicant-login');
  }

  const handlePasswordChange = () => {
    sessionStorage.clear();
    navigate('/change-password');
  }
  return (
    <>
      <header className='py-2 container-fluid'>
        <nav className="navbar navbar-expand-lg navbar-light bg-white Bx-shadow hmgPd">
          <div className="container-fluid">
            <a className="navbar-brand me-auto BrandLogo" href="/"> <img src={universityDetails.logo_url ? universityDetails.logo_url : alogo} style={universityDetails ? {} : { width: '230px' }} alt="university logo" /> <span>{universityDetails.university_name || 'University Name'}, {universityDetails.location || 'Location'}</span> </a>
            <div>
              <form className="d-flex ">
                <button className="btn loginBtn Hicons" type="submit" onClick={() => { navigate('/home') }}><span><img src={LginIcon} alt="login icon" /> </span> Home</button>
                <button className="btn siginBtn Hicons" type="submit" onClick={handleLogout}><span><img src={ApplyIcon} alt="register icon" /> </span> Log out</button>
                {/* <button className="btn siginBtn Hicons"><span><img src={LginIcon} alt = "login icon"/> </span>{username}</button> */}

                <div className="btn-group float-end">
                  <a data-bs-toggle="dropdown" className='userIcon' aria-expanded="true">
                    <img className='cursor' src={usericon} />
                  </a>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li><button className="dropdown-item" type="button" onClick={handlePasswordChange}>Change Password</button></li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  )
};

export default Navbar;
