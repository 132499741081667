

import React from 'react'

export const Activeapplications = ({ count, onViewDetails }) => {
  return (
    <>
    <div className='col-xs-12 col-lg-4'>
      <div className='BxApple'>
            <div className='row'>
              <div className='col-4'>
                <div className='bxActive'>
                {count}
                </div>
              </div>
              <div className='col-8'>
                <div className='bxTitle mt-3'>
                  <h1>
                    Active <br />
                    Applications
                  </h1>
                </div>
              </div>
              <div className='col-lg-12 mt-3'>
                <hr />
                <div className='bxViewTitle'>
                <a href="#" onClick={(e) => { e.preventDefault(); onViewDetails(); }}>
                  View details
                </a>
                </div>
              </div>
            </div>
          </div>  
          </div>
    </>
  )
}
