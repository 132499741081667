

import React from 'react';
import { Chart as ChartJS, ArcElement, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, LineElement, PointElement, LinearScale, CategoryScale, Tooltip, Legend);

const Applicationstatus = ({count}) => {
  
  const doughnutChartData = {
    labels: [
      "Active Applications",
      "Total Submitted Applications",
      "Total Not Submitted Applications",
    ],
    datasets: [
      {
        data: [
          count.active_applications_percent, 
          count.submitted_applications_percent, 
          count.not_submitted_applications_percent, 
        ],
        backgroundColor: ["orange", "green", "blue"],
        hoverBackgroundColor: ["#FFA500", "#32CD32", "#1E90FF"],
      },
    ],
  };
  const doughnutChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        onClick: null, // Disable click action on legend
      },
      tooltip: {
        enabled: true, // Enable tooltips for better user interaction
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}%`; // Customize tooltip content
          },
        },
      },
    },
  };
  return (
    <>
       <div className='col-xs-12 col-lg-4'>
          <div className='BxApple'>
            <div className='bxTitle'>
              <h1>Applications Status</h1>
            </div>
            <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
          </div>
        </div>
    </>
  )
}

export default Applicationstatus
