

import React from 'react'

const Submittedapplications = ({count, onViewDetails}) => {
  return (
    <>
    <div className='col-xs-12 col-lg-4'>
      <div className='BxApple'>
            <div className='row'>
              <div className='col-4'>
                <div className='bxSumbAct'>
                {count}
                </div>
              </div>
              <div className='col-8'>
                <div className='bxTitle mt-3'>
                  <h1>
                    Submitted <br />
                    Applications
                  </h1>
                </div>
              </div>
              <div className='col-lg-12 mt-3'>
                <hr />
                <div className='bxViewTitle'>
                <a href="#" onClick={(e) => { e.preventDefault(); onViewDetails(); }}>
                  View details
                </a>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Submittedapplications
