import {HttpClient} from '../http-service/api';
import {API_LIST} from '../http-service/list';

export const GETUSERS = (payload) => {
    return HttpClient.get(API_LIST.GETUSERS,payload);
}
export const ADDUSER = (payload) => {
    return HttpClient.post(API_LIST.ADDUSER,payload);
}
export const UPDATEUSER = (payload) => {
    return HttpClient.post(API_LIST.UPDATEUSER,payload);
}
export const DELETEUSER = (payload) => {
   return HttpClient.post(API_LIST.DELETEUSER,payload);
}
export const GETREPORTS = (payload) => {
    return HttpClient.post(API_LIST.GETREPORTS,payload);
 }